/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-17 09:37:19
 * @Module: 个案
 */
 <template>
  <div class="icase">
    <div style="height:68px;background:#fff;dispaly:flex; align-items: center;	padding: 0 16px;overflow:hidden;">
      <searchForm :searchForm.sync="listParams" @submit="getIcaseList" />
    </div>

    <div style="padding:16px;background:#fff;margin-top:24px;border-radius: 4px;padding-top:0px;">
      <div style="display: flex;	justify-content: space-between;	align-items: center;height:56px;">
        <div>案件列表</div>
        <span v-if="['SUPER'].includes(user.role)">
          <el-button type="text" size="small" @click="()=>showIcaseFrom('add')">添加个案</el-button>
        </span>
      </div>
      <el-table size="mini" :data="list" tooltip-effect="dark" style="width: 100%;margin-top:10px;">
        <el-table-column type="expand" v-if="['SUPER'].includes(user.role)">
          <template slot-scope="prop">
            <div style="padding:0 60px;">
              <el-descriptions :column="1" labelClassName="contentLabel" contentClassName="contentValue">
                <el-descriptions-item label="联系方式">{{prop.row.contact||"--"}}</el-descriptions-item>
                <el-descriptions-item label="承办律师">{{prop.row.lawyer||"--"}}</el-descriptions-item>
                <el-descriptions-item label="案件来源">{{prop.row.source||"--"}}</el-descriptions-item>
                <el-descriptions-item label="收费详情">{{prop.row.costDetail||"--"}}</el-descriptions-item>
                <el-descriptions-item label="情况描述">
                  <span>{{prop.row.cause||"--"}}</span>
                </el-descriptions-item>
                <el-descriptions-item label="分成方案">
                  <span> {{prop.row.percentageScheme||"--"}}</span>
                </el-descriptions-item>
                <el-descriptions-item label="附件">
                  <a class="moving" :href="prop.row.attachment" target="_blank" rel="noopener noreferrer">{{prop.row.attachment ||'--'}}</a>
                </el-descriptions-item>
              </el-descriptions>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="编号" show-overflow-tooltip width="120" prop="number">
          <template slot-scope="scope">
            <span>{{ scope.row.number||"--" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="客户" show-overflow-tooltip width="100" prop="customer">
          <template slot-scope="scope">
            <span>{{ scope.row.customer||"--" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="委托事项" show-overflow-tooltip width="100" prop="commitment">
          <template slot-scope="scope">
            <span>{{ scope.row.commitment||"--" }}</span>
          </template>
        </el-table-column>

        <el-table-column label="结清" show-overflow-tooltip width="80" prop="settled">
          <template slot-scope="scope">

            <div v-if="scope.row.settled" style="display:flex;align-items:center">
              <i class="el-icon-ali-dian1" style="color:#0AC258;"></i>
              <span style="color:#262626;">已结清</span>
            </div>
            <div v-else style="display:flex;align-items:center">
              <i class="el-icon-ali-dian1" style="color:#FF0D0D;"></i>
              <span style="color:#262626;">未结清</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="收款单位" show-overflow-tooltip width="100" prop="payee">
          <template slot-scope="scope">
            <span>{{ scope.row.payee ||"--"}}</span>
          </template>
        </el-table-column>
        <el-table-column label="当前进度" show-overflow-tooltip prop="payee">
          <template slot-scope="scope">
            <span>{{ scope.row.detail ||"--"}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="60" :resizable="false" v-if="['SUPER'].includes(user.role)">
          <template slot-scope="scope">
            <el-button type="text" size="mini" icon="el-icon-edit" @click="()=>showIcaseFrom('revision',scope.row)"></el-button>
            <el-button type="text" size="mini" icon="el-icon-delete" @click="()=>deleteIcase(scope.row)"></el-button>
          </template>
        </el-table-column>

        <!-- <el-table-column label="情况描述" show-overflow-tooltip min-width="60" prop="cause">

        <template slot-scope="scope">
          <el-popover placement="top-start" trigger="click">
            <span class="text">{{ scope.row.cause||"--" }}</span>
            <span class="text keep2lines pointer" slot="reference">{{ scope.row.cause||"--" }}</span>
          </el-popover>
        </template>

      </el-table-column> -->
        <!-- <el-table-column label="收费详情" show-overflow-tooltip min-width="60" prop="costDetail">

        <template slot-scope="scope">
          <el-popover placement="top-start" trigger="click">
            <span class="text">{{ scope.row.costDetail||"--" }}</span>
            <span class="text keep2lines pointer" slot="reference">{{ scope.row.costDetail||"--" }}</span>
          </el-popover>
        </template>

      </el-table-column> -->

      </el-table>
      <c-pagination ref="pagination" layout="prev, pager, next,sizes" @change="getIcaseList" />
    </div>

    <icaseFrom @success="getIcaseList" ref="icaseFrom" />
  </div>
</template>
 <script>
import icaseFrom from "@/views/Icase/components/icaseFrom"
import searchForm from "@/views/Icase/components/searchForm"
import { mapState } from "vuex";
export default {
  components: {
    icaseFrom,
    searchForm
  },
  data () {
    return {
      list: [],
      listParams: {
        payee: '',
        lawyer: '',
        keyword: '',
        settled: ''
      }
    };
  },
  mounted () {
    this.getIcaseList()
  },
  computed: {
    ...mapState({
      user: state => state.userInfo.user
    })
  },
  methods: {
    async getIcaseList () {
      const { current, size } = this.$refs.pagination
      const { listParams } = this
      const { status, data } = await this.$api.getIcaseList({ current, size, ...listParams })
      if (status == 200) {
        this.list = data.records;
        this.$refs.pagination.createPagination(data)
      }

    },
    showIcaseFrom (type, data) {
      if (type == 'add') {
        this.$refs.icaseFrom.show({ type })
      } else if (type == 'revision') {
        this.$refs.icaseFrom.show({ type, data })
      }

    },
    async deleteIcase ({ uuid, number }) {
      try {
        await this.$confirm(`此操作将永久删除编号为《${number}》的案件， 并需要验证管理员手机验证码，是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        await this.$api.postDeleteIcase();
        const { value: verifyCode } = await this.$prompt('请输入管理员收到的验证码', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        })
        const { status, info } = await this.$api.deleteIcase({ verifyCode, uuid })
        if (status == 200) {
          this.$message({
            message: info,
            type: 'success'
          })
          this.getIcaseList()
        } else {
          this.$message({
            message: info,
            type: 'error'
          })
        }
      } catch (error) {
        console.log(error)
      }

    }
  },
};
 </script>
 <style lang='scss' scoped>
// .text {
//   white-space: pre-wrap;
//   line-height: 1.2;
//   color: #666;
// }
.icase {
  &/deep/.contentLabel {
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    /* identical to box height */

    /* 字体/999999 */

    color: #999999;
  }
  &/deep/.contentValue {
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    /* identical to box height */

    /* 字体/262626 */

    color: #262626;
  }
}
</style>