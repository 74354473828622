/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-17 09:53:13
 * @Module: 个案表单
 */
<template>
  <el-dialog :title="type=='add'?'添加个案':type=='revision'?'修改个案':''" :visible.sync="dialogVisible" width="800px" @closed="closed">
    <div style="padding:20px;overflow-y:scroll;height:400px">
      <el-form size="mini" label-position="top" label-width="120px" :model="form" ref="form">
        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item label="编号" prop="number" :rules="{ required: true, message: '请输入编号'}">
              <el-input v-model="form.number" placeholder="请输入编号"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="客户" prop="customer" :rules="{ required: true, message: '请输入客户'}">
              <el-input v-model="form.customer" placeholder="请输入客户"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系方式" prop="contact">
              <el-input v-model="form.contact" placeholder="请输入联系方式"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="委托事项" prop="commitment">
              <el-input v-model="form.commitment" placeholder="请输入委托事项"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="收款单位" prop="payee">
              <el-input v-model="form.payee" placeholder="请输入收款单位"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="承办律师" prop="lawyer">
              <el-select v-model="form.lawyer" style="width:100%;" no-data-text="输入后按回车键添加承办律师" multiple filterable allow-create default-first-option placeholder="请输入承办律师">

              </el-select>

              <!-- <el-input v-model="form.lawyer" placeholder="请输入承办律师"></el-input> -->
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="案件来源" prop="lawyer">
              <el-input v-model="form.source" placeholder="请输入案件来源"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="附件" prop="attachment">
              <el-input v-model="form.attachment" placeholder="请输入附件地址"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="结清" prop="settled">
              <el-select v-model="form.settled" placeholder="请选择" style="width:100%;">
                <el-option label="已结清" :value="true" />
                <el-option label="未结清" :value="false" />
              </el-select>
              <!-- <el-radio-group v-model="form.settled">
                <el-radio :label="true">已结清</el-radio>
                <el-radio :label="false">未结清</el-radio>
              </el-radio-group> -->
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <div style="height:86px;"></div>
          </el-col>

          <el-col :span="12">
            <el-form-item label="当前进度" prop="detail">
              <el-input v-model="form.detail" type="textarea" :autosize="{minRows: 4, maxRows: 12}" placeholder="请输入当前进度"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="分成方案" prop="percentageScheme">
              <el-input v-model="form.percentageScheme" type="textarea" :autosize="{minRows: 4, maxRows: 12}" placeholder="请输入分成方案"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="情况描述" prop="cause">
              <el-input v-model="form.cause" type="textarea" :autosize="{minRows: 4, maxRows: 12}" placeholder="请输入情况描述"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收费详情" prop="costDetail">
              <el-input v-model="form.costDetail" type="textarea" :autosize="{minRows: 4, maxRows: 12}" placeholder="请输入情况描述"></el-input>
            </el-form-item>
          </el-col>

        </el-row>

      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="close" size="mini">取 消</el-button>
      <el-button type="primary" @click="submit" size="mini">确 定</el-button>
    </span>
  </el-dialog>
</template>
 <script>
export default {
  components: {
  },
  data () {

    return {
      type: 'add',
      dialogVisible: false,
      form: {
        number: '',
        customer: '',
        contact: '',
        commitment: '',
        cause: '',
        costDetail: '',
        payee: '',
        lawyer: [],
        source: '',
        detail: '',
        percentageScheme: '',
        attachment: '',
        settled: false,
      },

    };
  },
  mounted () { },
  methods: {
    show ({ type = 'add', data }) {
      if (type == "revision") {
        let form = this.$utils.deepClone(data);
        if (form.lawyer) {
          form.lawyer = form.lawyer.split(",")
        }
        this.form = form
      }
      this.type = type
      this.dialogVisible = true
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.postIcase()
        } else {
          return false
        }
      })
    },
    close () {
      // this.form = { ...this.form, ...this.oldform };
      this.dialogVisible = false
    },
    closed () {
      this.form = {
        number: '',
        customer: '',
        contact: '',
        commitment: '',
        cause: '',
        costDetail: '',
        payee: '',
        lawyer: '',
        source: '',
        detail: '',
        percentageScheme: '',
        attachment: '',
        settled: false,
      }
    },
    async postIcase () {
      const api = {
        revision: {
          func: this.$api.putIcase,
          params: {
            ...this.form,
            lawsuitUuid: this.form.uuid,
            lawyer: this.form.lawyer.toString()
          }
        },
        add: {
          func: this.$api.postIcase,
          params: {
            ...this.form,
            lawyer: this.form.lawyer.toString()
          }
        }
      }

      const { status, data, info } = await api[this.type].func(api[this.type].params)
      if (status === 200) {
        this.$message({
          type: 'success',
          message: info
        })
        this.$emit('success', this.type)
        this.dialogVisible = false;
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }
    },
  },
};
 </script>
 <style lang='scss' scoped>
</style>